import React, { useEffect } from 'react'
import { AppRouter } from './routers/AppRouter';
import { Provider } from 'react-redux';
import { store } from './state/store/store';


export const DocsApp = () => {
    return (
        <Provider store={ store }>
            <AppRouter />
        </Provider>
    )
}
